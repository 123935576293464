import React from 'react'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { theme } from '../util/style'
import { setLightboxForm } from '../actions'
import Title from '../components/page-title'

import PageHeroGallery from '../components/page-hero-gallery'
import PageButtons from '../components/page-buttons'
import Copy from '../components/page-copy'
import Row from '../components/page-content-row'
import Image from '../components/page-image-new'
import PageOverview from '../components/page-overview'

const Iframe3d = ({ href }) => (
  <Row>
    <Flex flexWrap={['wrap', 'nowrap']}>
      <Box width={[1, 1]} px={2} pr={[0, 0]}>
        <FrameBox>
          <iframe
            width="100%"
            height="100%"
            src={href}
            frameBorder="0"
            allowFullScreen
            allow="vr"
          ></iframe>
        </FrameBox>
      </Box>
    </Flex>
  </Row>
)

const Page = () => {
  return (
    <>
      <SEO
        title="Display Haus"
        description="Experience our Display Haus, a home that engages with its
                surrounds and maximises the ability to enjoy the benefits of
                life in the subtropics."
      />
      <Row>
        <Flex flexWrap="wrap" flexDirection={['column', 'row']}>
          <Box width={[1, 6 / 12]} px={2}>
            <PageOverview>
              <p>
                Take the family through a 3D tour of our recently sold Display
                Haus and a selection of our Open Haus projects.
              </p>
            </PageOverview>
          </Box>
          <Box width={[1, 6 / 12]} px={2} ml={[0]}>
            <Copy>
              <p>
                While we can’t meet you at our Display Haus we’re looking
                forward to sharing some exciting news in 2022!
              </p>
            </Copy>
          </Box>
        </Flex>
      </Row>
      <Iframe3d href="https://my.matterport.com/show/?m=fqP8EPNVWCW" />
      <Iframe3d href="https://my.matterport.com/show/?m=M7N9kYccYD6" />
      <Iframe3d href="https://my.matterport.com/show/?m=gKLnxtKbNph" />
    </>
  )
}

export default Page

const FrameBox = styled.div`
  position: relative;
  height: 0px;
  padding-top: 56.27%;
  iframe {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
`

const TitleWrap = styled.div`
  a {
    text-decoration: none;
    color: ${theme.colors.grey1};
  }
`
